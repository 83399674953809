.checkoutHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  max-height: 120px;
  background-color: white;
}

.headerInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;

  span {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
  }
}

.checkoutTotals {
  display: flex;
  width: 50%;
  justify-content: space-between;
  align-items: center;
  height: 38px;
}

.loadingCartsSpinner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 85px;
}

.loadingCartsMsg {
  font-size: 22px;
  color: #313131;
}

.vendorCarts {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  gap: 25px;
  width: 100%;
}

.shippingLocationContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .shippingOptionsWrapper {
    display: flex;
    flex-direction: column;

    .shippingLabel {
      margin-bottom: 10px;
      font-weight: bold;
    }

    .shippingOptions,
    .placeholderBox {
      border: 1px solid gray;
      padding: 10px;
    }

    .placeholderBox {
      width: 30%;
    }

    .shippingOptions {
      width: 77%;
    }
  }
}
