.appRoot {
  background-color: #fcfcfc;
  min-height: 100vh;
  height: auto;
}

.spinnerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  gap: 1.5rem;
  padding-bottom: 10%;
}

.spinnerText {
  font-size: 1.25rem;
}

.facetAccordion:global(.MuiPaper-root) {
  margin: 0;
  align-items: center;
}

.facetAccordion:global(.MuiButtonBase-root) {
  min-height: 0;
}

.facetAccordionSummary {
  padding-left: 8px; // keep same as MUI AccordionDetails

  :global {
    .MuiAccordionSummary-content {
      margin-top: 0;
      margin-bottom: 0;
    }

    .MuiAccordionSummary-root {
      max-height: 120px;
    }

    .MuiAccordionSummary-content {
      width: fit-content;
    }
  }
}

.refinementList {
  :global {
    .ais-SearchBox-submit, .ais-SearchBox-reset {
      display: none;
    }

    .ais-SearchBox-input {
      font-size: 15px;
      width: 100%;
      border-radius: 9999px;
      border-color: #d2d6da;
      border-style: solid;
      border-width: 1px;
      height: 28px;
      padding: 0px 12px;
      display: flex;
      align-items: flex-end;
    }
  }
}

.prevNextBtnBorder {
  border-right: 1px solid #d2d6da;

  &[data-next-btn="true"] {
    border-left: 1px solid #d2d6da;
    border-right: none;
  }
}

.selectedPage {
  &[data-is-selected="true"] {
    font-weight: 600;
    border: 1px solid black;
  }

  &[data-is-selected="false"] {
    &:hover {
      background-color: #ebebeb;
      border-top: 1px solid #d2d6da;
      border-bottom: 1px solid #d2d6da;
      cursor: pointer;
    }
  }

  &[data-is-page="false"] {
    &:hover {
      border-top: none;
      border-bottom: none;
      cursor: pointer;
    }
  }
}

.sortFilter:global(.MuiStack-root) {
  margin-top: 1rem;
}
