.headerLogoWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 28px;
  margin-top: 35px;
  cursor: pointer;

  img {
    height: 125px;
    width: auto;
    display: block;
    margin: 0;
  }
}

.statusBarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 20px;
  margin-bottom: 60px;
}

.statusBarWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  height: auto;
  max-width: 100%;

  @media screen and (max-width: 500px) {
    width: 100%;
  }
}

.circleLineWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: calc(100% - 62px);

  @media screen and (max-width: 600px) {
    width: 100%;
  }
}

.circleWrapper,
.stepWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepLabel {
  font-size: 14px;
  margin-bottom: 4px;
}

.line {
  height: 2px;
  background-color: #000;
  flex-grow: 1;
}

.textWrapper {
  display: flex;
  justify-content: space-between;
  width: 600px;
  max-width: calc(100% - 32px);
  position: relative;
  top: 20px;
  gap: 1.5rem;
}

.circleFilled,
.circleHighlighted {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.checkIcon {
  color: white;
  font-size: 24px;
}

.circleHighlighted {
  background-color: transparent;
  border: 2px solid #000;
}

.stepText {
  margin-top: 8px;
  text-align: center;
}

.smallFieldsContainer {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  align-items: center;
  margin-top: 20px;
  width: 100%;
  //max-width: 600px;
  row-gap: 30px;
  margin: 0 auto;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;

  label {
    display: block;
    margin-bottom: 20px;
    height: 80px;

    input {
      margin-top: 8px;
      padding: 10px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid gray;
    }

    input.error {
      border-color: red;
    }
  }
}

.addressLine {
  margin-top: 20px;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 30px;
  label {
    display: block;
    margin-bottom: 20px;
    height: 80px;

    input {
      margin-top: 8px;
      padding: 10px;
      width: 100%;
      border-radius: 4px;
      border: 1px solid gray;
    }

    input.error {
      border-color: red;
    }
  }
}

@media screen and (max-width: 500px) {
  .smallFieldsContainer {
    //max-width: calc(100% - 64px);
    grid-template-columns: 1fr;
  }
}

.errorMessage {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}

.distributorTitle, .buyingGroupTitle {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 60px 0px 15px 0px;
  font-size: 1.25em;
  color: #414a4c;
}

.distributorContainer {
  padding-top: 20px;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: space-between;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
}

.option {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
  justify-content: flex-start; /* Ensure checkbox and label align consistently */
  width: 100%; /* Ensure the option takes the full width */
  
  label {
    cursor: pointer;
    margin-left: 10px;
    text-align: left; /* Ensure labels are aligned properly */
    width: 100%;
  }
}

@media screen and (max-width: 1000px) {
  .distributorTitle {
    padding-left: 20px;
  }
}

@media screen and (max-width: 800px) {
  .distributorContainer {
    padding-left: 120px;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .distributorContainer {
    padding-left: 20px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 500px) {
  .distributorContainer {
    padding-left: 20px;
  }
}

.confirmButtonContainer {
  text-align: center;
}

.dropdownMenu:global(.MuiButtonBase-root) {
  width: 100%;
  background-color: white;
  border: 1px solid gray;
  color: black;
  height: auto;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  align-items: center;
  text-transform: none;
  font-size: 16px;
  margin-top: 8px;
  overflow: hidden;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:hover {
    background-color: white;
  }
}

.dropdownContent:global(.MuiPaper-root) {
  .MuiPaper-root {
    width: 100%;
    color: rgb(55, 65, 81);
    font-size: 16px;
    border: 1px solid gray;
  }
}

.dropdownContent {
  ul {
    padding: 4px 0;
    border: 1px solid gray;
    border-radius: 4px;
  }
}

.placeholder:global(.MuiButtonBase-root) {
  color: gray;
}

.confirmButtonContainer {
  text-align: center;
  margin: 60px 0 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.confirmButton {
  margin: 20px 0 40px 0;
  color: white;
  width: 200px;
  &:hover {
    background-color: rgb(3, 3, 78);
  }
}

.buyingGroupNameInput {
  max-width: 340px;
  width: 100%;
}

.buyingGroupInputWrapper {
  display: flex; // Ensures flexbox layout
  flex-direction: column; // Aligns children vertically
  align-items: center; // Centers children horizontally
}

.optionsFixedWidth {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px; /* Adjust as necessary for your design */
  margin: 0 auto;
  padding-left: 156px;
}

@media screen and (max-width: 800px) {
  .optionsFixedWidth {
    padding-left: 120px;
  }
}

@media screen and (max-width: 600px) {
  .optionsFixedWidth {
    padding-left: 80px;
  }
}

@media screen and (max-width: 400px) {
  .optionsFixedWidth {
    padding-left: 40px;
  }
}
