.vendorSiteLink {
  display: flex;
  justify-content: center;
  gap: 4px;
  font-size: 12px;
  align-items: center;
  border-bottom: 1px solid transparent;

  svg {
    width: 16px;
    height: 16px;
  }

  &:hover {
    color: #757575;
    cursor: pointer;
    border-color: #757575;

    span {
      color: #757575;
    }
  }
}

.inventoryContent {
  position: relative;
  display: flex;

  &[data-full-display="false"] {
    max-height: 525px;
  }
}

.centralContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-bottom: 0.5em;
  flex-direction: row;

  &[data-full-display="false"] {
    max-height: 525px;
  }

  &[data-ss-cart-warning="true"] {
    max-height: 430px;
  }
}

.closeInventoryBtn {
  position: absolute;
  bottom: 12px;
  left: 45%;
}

.vendorInventoryWrapper {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  width: auto;
  padding-bottom: 12px;
}

.vendorActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: 0 1em;
  gap: 14px;
  width: 200px;
  padding-bottom: 36px;
}

.dataSpinnerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  gap: 0.5rem;

  span {
    font-size: 1.33em;
  }
}

.noDataMsg {
  display: flex;
  justify-content: center;
  font-size: 1.33em;
  padding: 1rem;
}

.inventoryTables {
  cursor: default;
}

.inventoryTableHeader {
  overflow: hidden;
  border-top: 1px solid #d2d6da;
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
    overflow-y: scroll;

    th {
      background-color: #dce3e8;
    }
  }
}

.inventoryTableBody {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;

  &[data-full-display="false"] {
    max-height: 320px;
    overflow-y: scroll;
  }

  table {
    width: 100%;
    table-layout: fixed;
    border-collapse: collapse;
  }

  td {
    text-align: right;
  }

  &[data-ss-cart-warning="true"] {
    max-height: 250px;
  }
}

.inventoryTableBodySaveState {
  @extend .inventoryTableBody;
  overflow-y: hidden; // don't want table to be scrollable when in loading state
}

.inventoryTableBodySaveState::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(88, 88, 88, 0.2);
}

.saveToCartLoadingState {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  &[data-display-loading-animation="false"] {
    opacity: 0;
  }
}

.inventoryColorCell {
  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    font-size: 0.9em;
  }
}

.invColorTextWrapper {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.inventoryCell {
  padding: 4px;
}

.inventoryNumberContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inventoryNumber {
  text-align: center;
  text-align: right;
  width: 50px;
  padding: 3px;

  &[data-case-qty-required="true"] {
    font-weight: 600;
  }

  &[data-need-to-call="true"] {
    span {
      color: #a8a8a8;
    }
  }
}

.inventoryInput {
  width: 50px;
  height: 26px;
  box-sizing: border-box;
  text-align: center;
  border-width: thin;
  outline-width: thin;
  border-radius: 5px;
  border: 1px solid #c8c8c8;

  &:hover {
    &[data-is-valid="true"] {
      @extend .focused;
    }
  }

  &[data-need-to-call="true"] {
    color: #a8a8a8;
    border: 1px solid #a8a8a8;
    cursor: default;
  }
}

// styles.module.scss
.inventoryInput::placeholder {
  color: #DCDCDC; // Very light gray color for the placeholder
  font-size: 0.8em; // Adjust the font size as needed
}

.disabledCell {
  border: 1px solid #d7d7d7;
  outline: 1px solid #bbbbbb;
  background: linear-gradient(
      rgba(115, 115, 115, 0.26),
      rgba(115, 115, 115, 0.3)
    ),
    transparent;
  pointer-events: none;
  cursor: default;
}

.error {
  border: 1px solid red;
  outline: 1px solid red;
  box-shadow: 0 0 4px red;
  background-color: rgba(200, 0, 0, 0.1);
}

.focused {
  border: 1px solid rgb(2 129 192); // blueblue
  outline: 1px solid rgb(2 129 192); // blueblue
}

.greenHighlight {
  background-color: rgba(0, 255, 0, 0.131);
}

.warehouseInfoCell {
  width: 15%;
}

.warehouseInfoText {
  display: inline-block;
  vertical-align: middle;
  font-size: 0.8em;
}

.warehouseName {
  text-align: center;
  display: inline-block;
  vertical-align: middle;
}

.vendorTabs {
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 2px;
}

.vendorTab {
  flex: 1 1 auto;
  width: 100%;
  max-width: 200px;
  height: 45px;
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c3c5cd;
  border-radius: 5px 5px 0 0;
  padding: 10px;
  border-bottom: none;

  &:hover {
    cursor: pointer;
    box-shadow: 0px -8px 12px -10px #9aa2b47f;
    height: 50px;
  }

  img {
    width: auto;
    max-width: 180px;
    height: 30px;
    display: block;
    margin: 0 auto;
    padding: 5px;
  }

  &[data-downsize="true"] {
    img {
      height: 20px;
    }
  }

  &[data-selected="true"] {
    height: 60px;
    box-shadow: 0px -8px 12px -10px #9aa2b47f;

    img {
      height: 40px;
    }

    &[data-downsize="true"] {
      img {
        height: 25px;
      }
    }
  }
}

@media (max-width: 1300px) {
  .vendorTab {
    img {
      height: 29px;
    }
  }
}

@media (max-width: 900px) {
  .vendorTab {
    img {
      height: 24px;
    }
  }
}
