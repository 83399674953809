.terms {
  padding: 2rem 1rem 2rem 1rem;

  h1:first-child {
    margin-top: 0;
  }
}

.contentWrapper {
  max-width: 1000px;
  margin: 0 auto;

  @media (max-width: 1000px) {
    padding: 1rem;
  }
}
