.integrationsPageBackground {
  background-color: #fcfcfc;
  padding: 10px;
  padding-top: 2rem;
}

.connectionPage {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #d2d6da;
  margin: 0 2rem 1.33rem 2rem;
  padding: 1rem;
  min-height: calc(100vh - 64px);
  background-color: white;
  padding-top: 20px;

  h1, h2, h3 {
    color: rgba(65, 75, 86, 1), // charcoal
  }
}

.connectionPageContent {
  display: flex;
  flex-grow: 1;
  box-sizing:inherit;
}

.connectionPageHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 6px;
}

.connectionModules {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  align-items: start;
  align-content: start;
  justify-content: center;
  padding-right: 1rem; // Default padding
  margin: 0 auto;
  flex-shrink: 1;
  flex-basis: 50%;
  flex-grow: 1;

  &::after {
    content: '';
    flex-basis: 100%;
    width: 0;
    height: 0;
    order: 1;
  }

  // Remove right padding for screens below 1000px
  @media (max-width: 1000px) {
    padding-right: 0;
  }
}

.distributorContainer {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid #d2d6da;
  padding-top: 0.25rem;
  height: 10.5rem;
  max-width: 290px;
  width: 100%;
}

.vendorHeader {
  border-bottom: 1px solid #d2d6da;
  padding: 0.8rem;
  text-align: center;
  font-size: 20px;
  font-weight: semi-bold;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;

  img {
    width: auto;
    height: 30px;
    display: block;
    margin: 0 auto;
    max-width: 80%;
  }

  &[data-downsize="true"] {
    img {
      height: 25px;
    }
  }
}

.connectionStatusContent {
  display: flex;
  align-items: center;
}

.connectionIcon {
  vertical-align: bottom;
  color: gray;
  margin: 0 12px 0 12px;
  font-size: 30;
}

.connectButtonContainer {
  display: flex;
  justify-content: center;
}

.distributorLogin {
  width: 45vw;
  height: auto;
  padding: 1rem;
  padding-bottom: 0rem;

  h1 {
    margin-bottom: 0.25rem;
  }

  p {
    text-align: center;
    color: gray;
  }
}

.loginLogoWrapper {
  padding: 16px;

  img {
    width: auto;
    height: 60px;
    display: block;
    margin: 0 auto;
  }

  &[data-downsize="true"] {
    img {
      height: 45px;
    }
  }
}

@media (max-width: 600px) {
  .loginLogoWrapper img {
    height: 40px;
  }
}

@media (max-width: 460px) {
  .loginLogoWrapper img {
    height: 30px;
  }
}

.saveLoginProgress:global(.MuiLinearProgress-root) {
  width: 70%;
  margin: 1.75rem 0 0.5rem 0;
  padding: 0.16rem;
  background-color: #8d8dff;
}

.more {
  color: rgb(125 125 221);
  cursor: pointer;
}

.dialogDescription {
  text-align: center;
}

.connectedText {
  font-size: 1.15em;
  vertical-align: top;
}

.notConnectedText {
  color: gray;
  font-size: 1.15em;
}

.comingSoonText {
  color: darkslategray;
  font-size: 1.15em;
}

.alertMessage:global(.MuiAlert-root) {
  font-size: 1rem;
  width: 100%;
}

.loginField {
  border-radius: 5px;
  width: 70%;

  &[data-border="true"] {
    border: 1px solid slategray;
  }
}

.passwordIcon {
  width: 27px;
  height: 27px;
}

.resetPasswordLink {
  display: block;
  text-decoration: none;
  font-size: 14px;
}

.connectionInfo {
  border-left: 1px solid #d2d6da;
  padding-left: 1rem;
  max-width: 340px;
  width: 100%;
  transition: max-width 0.8s ease-in-out; 
  word-wrap: break-word;
  p {
    color: rgb(88, 88, 88);
  }
}

.connectionInfo.expanded {
  max-width: 900px;
}

@media (max-width: 1325px) {
  .distributorLogin {
    width: 50vw;
  }
}

@media (max-width: 1050px) {
  .distributorLogin {
    width: auto;
  }
}

@media (max-width: 1000px) {
  .connectionPageContent {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }

  .connectionInfo {
    border-left: none;
    width: 100%;
    max-width: none;
    padding-left: 0;
  }
}
