/* styles.module.scss */

.mainContent {
  min-height: calc(100vh - 64px);
  margin: auto;
  padding-bottom: 24px;
  background-color: #fcfcfc;
  max-width: 1600px;
}

.authedContentWrapper {
  background-color: #fcfcfc;

  &[data-profile="false"] {
    flex: 1;
    max-width: 100%;
    height: 100vh;
  }
}

.appRoot {
  background-color: #fcfcfc;
  min-height: 100vh;
  height: auto;
}

.signInForm {
  width: 100%;
}

.welcomeWrapper {
  min-height: calc(100vh - 64px);
}

.headerLogoWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 28px;
  margin-top: 35px;
  cursor: pointer;

  img {
    height: 125px;
    width: auto;
    display: block;
    margin: 0;
  }
}

/* Global styles to override Amplify Authenticator components */
:global {
  /* Tabs at the top */
  .amplify-tabs-item {
    color: #004975 !important;
  }

  .amplify-tabs-item[data-state="active"] {
    color: #004975 !important;
    --amplify-components-tabs-item-active-border-color: #004975 !important; /* Set your custom color here */
  }

  /* Primary buttons at the bottom */
  .amplify-button--primary {
    background-color: #004975 !important;
    color: #FFFFFF !important;
    border-color: #004975 !important;
  }

  .amplify-button--primary:hover {
    background-color: #004070 !important; /* Optional: darker shade on hover */
    color: #FFFFFF !important;
    border-color: #004070 !important;
  }

  /* Disabled state for primary buttons */
  .amplify-button--primary[disabled],
  .amplify-button--primary.amplify-button--disabled,
  .amplify-button--primary[aria-disabled="true"] {
    background-color: #CCCCCC !important; /* Gray background */
    color: #666666 !important;            /* Gray text */
    border-color: #CCCCCC !important;     /* Gray border */
    cursor: not-allowed !important;
  }

  /* Optional: Style link-style buttons */
  .amplify-button--link {
    color: #004975 !important;
  }

  .amplify-button--link:hover {
    color: #004070 !important;
  }
}
