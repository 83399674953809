.teamImagesContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 20px;
  justify-content: center;
}

.teamImage {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin: 3px;
}

.teamMember {
  display: flex;
  flex-direction: column;
  align-items: center;
}
