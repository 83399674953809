.shimmer {
    display: inline-block;

    background: #444 -webkit-gradient(linear, 100% 0, 0 0, from(#acacac), color-stop(0.5, #ffffff), to(#acacac));

    background-position: -4rem top;
    background-repeat: no-repeat;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: shimmer 3s infinite;
    background-size: 4rem 100%;
}
  
@keyframes shimmer {
    0% {
        background-position: -4rem top;
    }

    50% {
        background-position: 12.5rem top;
    }

    100% {
        background-position: 12.5rem top;
    }
}