.vendorLogo {
  display: flex;
  align-items: center;

  &[data-upsize="true"] {
    img {
      height: 1.25rem;
    }
  }

  &[data-downsize="true"] {
    img {
      height: 0.9rem;
    }
  }

  &[data-downsize-more="true"] {
    img {
      height: 0.6rem;
    }
  }

  img {
    height: 1rem;
    width: auto;
    display: block;
    margin: 0;
  }
}

