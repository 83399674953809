.items {
  display: flex;
  flex-wrap: wrap;
  padding: 1em 0.5em 1em 1em;
  gap: 25px;
  flex-grow: 1;
  height: fit-content;
}

@media (max-width: 1250px) {
  .items {
    padding: 1em 1em 1em 1em;
  }
}

.productContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.expandedContainer {
  border-radius: 5px;
  width: 100%;
  height: 200px;
  border: 1px solid #d2d6da;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  transition: box-shadow 0.3s;
}

.previewContainer {
  @extend .expandedContainer;
  height: 200px;

  &:hover {
    box-shadow:
      0 15px 35px rgba(50, 50, 93, 0.1),
      0 5px 15px rgba(0, 0, 0, 0.07);
  }
}

.productInformation {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;

  @media (max-width: 1024px) {
    flex-wrap: wrap;
  }
}

.productInformation:hover .productInfoText {
  color: rgba(0, 110, 165, 1) // blueblue
}

.slideContainer {
  position: relative;
  width: 95%;
  overflow: hidden;
  transition:
    height 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  height: 0;
  opacity: 0;
  z-index: 10;

  &[data-open="true"] {
    height: 510px;
    opacity: 1;
  }

  @media (max-width: 768px) { // tailwind md
    display: none;
  }
}

.similarSlideContainer {
  position: relative;
  width: 95%;
  overflow: hidden;
  transition:
    height 0.3s ease-in-out,
    opacity 0.3s ease-in-out;
  height: 0;
  opacity: 0;
  z-index: 20;

  &[data-open="true"] {
    height: 411px;
    opacity: 1;
  }

  @media (max-width: 768px) { // tailwind md
    display: none;
  }
}

.detailsSlide {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  border-left: 1px solid #d2d6da;
  border-right: 1px solid #d2d6da;
  border-bottom: 1px solid #d2d6da;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  max-height: 510px;
  position: relative;
}

.productDetails {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 9px;
  padding: 0.25em 0.75em 0 0;
  background-color: #fff;
  width: auto;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
  border-radius: 5px;

  @media (max-width: 1024px) {
    padding-left: 1em;
  }
}

.productPrices {
  border-left: 1px solid #d2d6da;
  width: auto;
  display: flex;
  flex-direction: column;
  height: auto;
}

@media (max-width: 768px) {
  .productPrices {
    border-left: none;
    border-top: 1px solid #d2d6da;
  }

  .pricesTableBody > tr:last-child {
    border-bottom: none;
  }
}

.productPriceRow {
  position: relative;
  cursor: pointer;
  height: 40px;
  border-bottom: 1px solid #d2d6da;
  border-width: thin;

  &:hover {
    background-color: #f4f4f4;
  }
}

@media (max-width: 640px) {
  .renderCutoffCell {
    display: none;
  }
}

@media (max-width: 768px) {
  .vendorLogoCell {
    width: 38% !important;
  }

  .productStyleCell {
    width: 22% !important;
  }

  .renderCutoffCell {
    width: 22% !important;
  }

  .renderPriceCell {
    width: 27% !important;
  }
}

:global {
  .vendorRowCell.MuiTableCell-root {
    padding: 0px;
    border-bottom: none;
  }
}

.priceTableVendorLogo {
  display: flex;
  align-items: center;

  &[data-upsize="true"] {
    img {
      height: 1.25rem;
    }
  }

  &[data-downsize="true"] {
    img {
      height: 0.9rem;
    }
  }

  img {
    height: 1rem;
    width: auto;
    display: block;
    margin: 0;
  }
}

.vendorPriceContainer {
  display: flex;
  justify-content: flex-end;
}

.styleCellText {
  font-size: 1.25em;
}

.launchIcon {
  position: absolute;
  right: 28px;
}

.headerText {
  font-weight: bold;
  font-size: 1.1em;
}

.bottomDetails {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 6px;
  width: 100%;

  @media (max-width: 768px) { // tailwind md
    display: none;
  }
}

.colorSelection {
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  gap: 4px;
  padding: 2px;
  width: 1200px;
  overflow-y: scroll;
}

.shortColorSelection {
  display: flex;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  padding-bottom: 5px;
}

.colorSwatches {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
}

.priceParam {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.25rem;
  padding: 2px;
  border-radius: 5px;
  position: relative;

  &[data-short-view="true"] {
    overflow: visible;
  }

  &[data-dialog="true"] {
    flex: 0 0 120px;
  }

  &:hover {
    cursor: pointer;

    .colorSwatchWrapper {
      border-color: rgba(0, 0, 0, 0.2);
    }

    &[data-short-view="true"] {
      overflow: visible;

      .previewColor {
        opacity: 1;
      }
    }
  }

  &[data-selected="true"] {
    .colorSwatchWrapper {
      border-color: rgba(0, 0, 0, 0.8);
    }
  }
}

.colorSwatchWrapper {
  display: flex;
  align-items: center;
  padding: 2px;
  border-radius: 5px;
  border: 1px solid transparent;

  &:hover {
    border-color: rgba(0, 0, 0, 0.2);
  }

  &[data-is-disabled="true"] {
    pointer-events: none;
  }
}

.priceParamButton:global(.MuiButton-root) {
  padding: 5px;
  background: transparent;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #767575;
  cursor: pointer;

  &:hover {
    color: black;
  }

  &[data-color-param="true"] {
    min-width: 36px;
    max-width: 36px;
    min-height: 20px;
    max-height: 20px;
  }

  &[data-long="true"] {
    min-height: 36px;
    max-height: 36px;
  }
}

.previewColor {
  opacity: 0;
  position: absolute;
  left: 5%;
  top: 90%;
  white-space: nowrap;
}

.colorDialogButton {
  height: fit-content;
  margin: 0 0.25rem 0 0.25rem;
}

.colorSelectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.moreColorsPrompt {
  border-bottom: 1px solid transparent;

  svg {
    color: #5c606f;
    height: 16px;
    width: fit-content;
  }

  span {
    color: #5c606f;
  }

  &:hover {
    border-color: #5c606f;
    line-height: 1;
    cursor: pointer;
  }
}

.pricesTableSpinner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  gap: 1rem;
}

.detailsContent {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0;
}

.swatchTextWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

:global {
  .leftCarouselArrow.MuiIconButton-root {
    opacity: 0;
    cursor: default;
    pointer-events: none;
  }

  .rightCarouselArrow.MuiIconButton-root {
    opacity: 0;
    cursor: default;
    pointer-events: none;
  }
}
  
@media (max-width: 1100px) {
  .previewContainer {
    max-height: 100%;
  }

  .imageBox {
    width: auto;
    flex-grow: 0;
  }

  .expandedContainer {
    height: auto;
    max-height: none;
  }
}

@media (max-width: 900px) and (min-width: 700px) {
  :global {
    .vendorRowCell.MuiTableCell-root {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
}

@media (max-width: 700px) {
  .pricesTableContainer {
    &:global(.MuiPaper-root) {
      border-bottom-left-radius: 5px;
    }
  }
}

@media (max-width: 425px) {
  :global {
    .leftCarouselArrow.MuiIconButton-root {
      display: none;
    }

    .rightCarouselArrow.MuiIconButton-root {
      display: none;
    }
  }
}
