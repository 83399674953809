.confirmationHeader {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  max-height: 120px;
  background-color: white;
}

.headerInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 64px;
  background-color: rgb(34 106 173 / 90%);

  span {
    font-size: 24px;
    font-weight: bold;
    color: #fff;
  }
}

.confirmationProgress {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  font-size: 20px;

  span {
    margin: 0 4px;
  }
}
