/* @tailwind base; */
@tailwind components;
@tailwind utilities;

:root {
  --amplify-components-button-primary-background-color: #111 !important;
  --amplify-components-tabs-item-active-border-color: #111 !important;
  --amplify-components-tabs-item-active-color: #111 !important;
  --amplify-components-tabs-item-hover-color: #5c606f !important;
  --amplify-components-button-primary-hover-background-color: #5c606f !important;
  --amplify-components-button-hover-color: transparent !important;
  --amplify-components-button-link-hover-color: #111 !important;
  --amplify-components-button-link-hover-background-color: transparent !important;
  --amplify-components-button-link-color: #111 !important;
  --amplify-components-button-active-background-color: transparent !important;
  --amplify-components-button-link-focus-background-color: transparent !important;
  --amplify-components-button-hover-background-color: #eaeaec !important;
  --amplify-components-button-hover-color: none !important;
  --amplify-components-button-hover-border-color: #111 !important;
  --amplify-components-button-primary-active-background-color: #5c606f !important;
  --amplify-components-button-primary-focus-background-color: #5c606f !important;
  --amplify-internal-button-loading-border-color: #00274d !important;
  --amplify-internal-button-loading-color: #00274d !important;
}

body {
  margin: 0;
}

h1,
h2,
h3,
p,
a,
span {
  font-family: "Helvetica", "Arial", sans-serif;
  color: #150D0A;
  font-size: 1rem;
  line-height: 1.5rem;
}

.MuiTextField-root span {
  font-size: inherit;
  line-height: normal;
}

:global(.MuiTypography-root) {
  font-family: "Helvetica", "Arial", sans-serif;
  color: #150D0A;
  font-size: 1rem;
  line-height: 1.5rem;
}

/* color: #2a2a2a; */
