.mainHeader {
  min-height: 64px;
  // background: #373c48;
  background: #232f3e;
  display: flex;
  justify-content: space-between;

  &[data-landing-page="true"] {
    background-color: #fbfbfdcc;
  }
}

:global {
  .searchField {
    width: 100%;
    padding: 0 0.5rem;

    & {
      .MuiOutlinedInput-notchedOutline {
        display: none;
      }
    }
  }
}

.logoContainer {
  display: flex;
  cursor: pointer;
  transition: transform 0.3s;
  padding: 0 20px 0 20px;
}

.logoContainer:hover {
  transform: scale(1.05);
}

.navButton {
  color: whitesmoke;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  font-size: inherit;
}

.navButton:hover {
  opacity: 0.8;
}

.landingPageLinks {
  display: flex;
  width: 100%;
  gap: 1rem;
  justify-content: flex-end;
  padding: 8px;
  align-items: center;
}

.landingPageLink {
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin-right: 16px;
  padding: 8px;
  position: relative;
  width: 160px;
  border: 1px solid #111;
  border-radius: 10px;
  border-width: thin;

  &:hover {
    cursor: pointer;
    color: #5c606f;
    border-color: #5c606f;
  }

  span {
    font-size: 18px;
  }
}

.textLink {
  color: #5c606f;
  cursor: pointer;
  margin-right: 20px;
  font-size: 1.1em;

  &:hover {
    opacity: 0.8;
  }
}

.mobileMenu {
  background-color: #373c48;
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.mobileMenuLink {
  color: #000000;
  font-size: 1.2em;
  padding: 0.5rem 1rem;
  text-align: center;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.hamburgerMenuWrapper {
  display: flex;
  align-items: center;
  padding: 16px;
  z-index: 2000;
}

.iconBlack:global(.MuiSvgIcon-root) {
  color: #000000;
  cursor: pointer;
  height: 3em;
  width: 3em;
  font-size: 16px; // fix this
}

.alertBanner {
  height: fit-content;
  min-height: 34px;
  color: black;
  font-size: 16px;
  background-color: #f6f9a0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 2001;
}

.searchBox > * {
  z-index: 1350;
}

.facetItem {
  display: block;
  height: 30px;
  position: relative;
  width: 200px;
  padding-left: 25px;
  line-height: 30px;
  border-bottom: 2px solid transparent;
  cursor: pointer;

  &[data-is-hierarchical="false"] {
    width: fit-content;
    padding-left: 0;
  }

  &[data-hover-upper-category="true"] {
    background-color: orange;

    span {
      color: white;
    }

    :after {
      content: "";
      position: absolute;
      height: 0;
      width: 0;
      left: 100%;
      top: 0;
      border: 15px solid transparent;
      border-left: 15px solid orange;
    }
  }
}

.facetItem:hover {
  &[data-is-hierarchical="false"] {
    span {
      color: rgba(0, 88, 141, 1); // denim
    }
    border-color: rgba(0, 88, 141, 1); // denim
  }
}

.customPlaceholder::placeholder {
  transform: translateY(2px);
  display: inline-block;
}
